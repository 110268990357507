@import 'variables.scss';
@import 'mixins.scss';

.or-form-schedule {
  display: block;
  position: relative;

  .or-form-item {
    width: auto;
  }

  .or-day-of-week {
    margin-right: 4px;
  }

  .or-day-of-week-toggle {
    min-width: 4em;
    padding: 0 8px;

    &.or-active {
      background-color: $bg-accent;
      color: $text-inverse;
    }
  }
}

.or-form-schedule-details {
  display: block;
}

.or-form-schedule-details-name {
  width: 20%;
}

.or-form-schedule-details-from-time,
.or-form-schedule-details-to-time {
  select {
    min-width: 10%;
  }
}

.or-form-schedule-details-days-of-week {
  width: auto;
}

.or-form-schedule-ruleset {
  padding: 10px 0;
}

.or-form-schedule-ruleset-rule {
  border-top: $border-outline;
  padding: 10px 0;

  &:last-child {
    border-bottom: $border-outline;
  }
}

.or-schedule-summary-dated {
  padding-top: 8px;
}
