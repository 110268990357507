@import 'variables.scss';

$bg-page-solid: rgba($bg-page, 1);
$bg-page-transparent: rgba($bg-page, 0);

#orLightingConfiguration {
  .or-panel-selector {
    margin: 5px 5px 0;
  }

  .or-content {
    height: 88vh;
  }

  .or-panel {
    margin: 5px;
  }

  .or-panel-nodes {
    &.or-active {
      flex-basis: 400px;
      flex-grow: 1;
    }

    .or-panel-content {
      position: relative;
      height: 100%;

      &:before,
      &:after {
        background: $bg-page;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
      }
    }
  }

  .or-panel-container {
    &.or-active {
      flex-basis: 1200px;
    }
  }

  .or-panel-floorplan {
    &.or-active {
      flex-basis: 500px;
      flex-grow: 1;
    }
  }

  .or-panel-configuration {
    &.or-active {
      flex-basis: 300px;
    }
  }

  .or-flex-lighting-config {
    position: relative;
  }

  .or-query-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
