.emergency-lighting-test-results {
  .emergency-lighting-test-results-list {
    overflow-y: scroll;
    height: 79vh;
  }

  .emergency-lighting-test-result-header-titles {
    font-weight: bold;
  }

  .emergency-lighting-test-result-value {
    display: inline-block;
    width: 20%;
    padding: 10px;
    text-align: center;
  }

  .emergency-lighting-test-result-message {
    width: 40%;
  }
}
