@import 'variables.scss';

.or-datepicker {
  display: block;
  position: relative;
  z-index: 100;

  .or-label {
    opacity: 1;
  }

  ._720kb-datepicker-calendar {
    width: 320px;
    box-shadow: 0 10px 10px 0 rgba($text, 0.1), 0 10px 30px 0 rgba($text, 0.1);
  }

  ._720kb-datepicker-calendar-header {
    background-color: $bg;
  }

  ._720kb-datepicker-calendar-header-middle {
    color: $text;
  }

  ._720kb-datepicker-calendar-days-header {
    background-color: inherit;
  }

  ._720kb-datepicker-calendar-body {
    font-size: 1rem;
  }

  ._720kb-datepicker-calendar-day {
    border-radius: 100rem;
    padding: 0;

    &._720kb-datepicker-active {
      background-color: $bg-accent;
      color: $text-inverse;
    }
  }
}
