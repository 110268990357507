@import 'variables.scss';

.or-nodes-list {
  overflow: hidden;
  position: relative;
}

.or-nodes-list-body {
  height: 93vh;
  overflow: hidden;
}

.or-select-label {
  color: $text-muted;
  font-size: 12px;
  padding: 5px 0 0 5px;
}

.or-nodes-list-items {
  height: 90%;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 5px 5px 5px;
}

.or-nodes-list-item {
  padding: 4px 0;
  position: relative;
  z-index: 1;
}

.or-nodes-list-control {
  margin: 0 -5px;

  .or-nodes-list-control-items {
    display: block;

    .or-nodes-list-control-item {
      margin-right: 0;
    }
  }
}

.or-nodes-list-filter-id {
  width: 20%;

  &.or-show-hidden {
    width: 18%;
  }
}

.or-nodes-list-filter-address {
  width: 25%;

  &.or-show-hidden {
    width: 23%;
  }
}

.or-nodes-list-sort {
  width: 20%;

  &.or-show-hidden {
    width: 28%;
  }
}

.or-nodes-list-filter {
  width: 18%;
}

.or-nodes-list-sort-reverse,
.or-nodes-list-reload,
.or-nodes-list-copy-to-clipboard {
  width: 7%;

  .or-button {
    opacity: 0.8;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    min-width: $icon-size-small;

    &:hover {
      opacity: 1;
    }
  }
}
