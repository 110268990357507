@import 'variables.scss';
@import 'mixins.scss';

.or-form-rule-details {
  .or-form-item {
    width: auto;
  }
}

.or-form-rule-details-type {
  select {
    min-width: 240px;
  }
}

.or-form-rule-details-value {
  input {
    min-width: 180px;
  }
}

.or-form-rule-details-label {
  min-width: 40px;
}

.or-form-rule-details-tags {
  width: 100% !important;
}

.or-form-rule-details-floors {
  width: 100% !important;
}
