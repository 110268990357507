@import 'variables.scss';
@import 'mixins.scss';

.or-multiselect {
  display: inline-block;
  vertical-align: top;
}

.or-multiselect-item {
  padding-right: $icon-size-small;
  position: relative;
}

.or-multiselect-item-label {
  line-height: $icon-size-medium;
}

.or-multiselect-remove-item {
  border-radius: 0;
  bottom: 0;
  box-shadow: none !important;
  min-width: auto !important;
  opacity: 0.4;
  padding: 0 !important;
  position: absolute !important;
  right: 0;
  top: 0;
  transition: opacity $transition-fast;
  width: $icon-size-small !important;
  background: none;

  &:hover {
    opacity: 1;
  }

  .or-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
