@import 'variables';

.or-luminaire-driver-tile {
  background-color: $bg;
  border-radius: 3px;
  box-shadow: $shadow;
  color: $text;
  padding: 10px;
  transition: opacity $transition-medium;
  opacity: 0;

  .or-show {
    opacity: 1;
  }
}

.or-luminaire-driver-tile-summary {
  cursor: pointer;

  .or-inline-items {
    display: block;

    .or-inline-item {
      margin-right: 2%;
    }
  }
}

.or-luminaire-driver-tile-value {
  display: block;
  font-size: 1rem;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.or-luminaire-driver-tile-has-background {
    border-radius: 3px;
    padding: 0 2px;
  }

  &.or-luminaire-driver-tile-light-text {
    color: $text-inverse;
  }

  &.or-luminaire-driver-tile-dark-text {
    color: $text-dark;
  }

  &.or-luminaire-driver-tile-inline-text {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
  }
}

.or-luminaire-driver-tile-label {
  color: $text-muted;
  display: block;
  font-size: 0.8rem;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.or-luminaire-driver-tile-status {
  min-width: $icon-size-small;
  width: 25px;
  padding: 2px;
}

.or-luminaire-driver-tile-status-body {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ccc;
  border-radius: 100px;
  display: inline-block;
  height: $icon-size-small;
  position: relative;
  width: $icon-size-small;

  .or-icon {
    background-position: top center;
    background-size: 12px;
    border-radius: 100px;
    height: 12px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
  }

  &.or-luminaire-driver-tile-status-body-FAULTY {
    background-color: $bg-negative;
    box-shadow: none;

    .or-icon {
      background-position: center center;
    }
  }

  &.or-luminaire-driver-tile-status-body-DISCONNECTED {
    background-color: $bg-deep-grey;
    box-shadow: none;

    .or-icon {
      background-position: center center;
    }
  }

  &.or-luminaire-driver-tile-status-body-UNKNOWN {
    background-color: $bg-inverse;
    box-shadow: none;

    .or-icon {
      background-position: center center;
    }
  }
}

.or-luminaire-driver-tile-id {
  color: $text-muted;
  width: 10%;

  &.or-show-hidden {
    width: 5%;
  }

  .or-luminaire-driver-tile-value {
    font-size: 1.2rem;
    line-height: 28px;
  }
}

.or-luminaire-driver-tile-address {
  width: 10%;

  &.or-show-hidden {
    width: 8%;
  }
}

.or-luminaire-driver-tile-gateway-address {
  width: 11%;
}

.or-luminaire-driver-tile-bacnet-address {
  width: 11%;
}

.or-luminaire-driver-tile-crestron-address {
  width: 11%;
}

.or-luminaire-driver-tile-ble-seq {
  width: 11%;
}

.or-luminaire-driver-tile-summary {
  .or-inline-items {
    .or-inline-item {
      &.or-luminaire-driver-tile-gateway-address {
        margin-right: 0;
      }

      &.or-luminaire-driver-tile-crestron-address {
        margin-right: 0;
      }

      &.or-luminaire-driver-tile-bacnet-address {
        margin-right: 0;
      }
    }
  }
}

.or-luminaire-driver-tile-padding {
  width: 14%;

  &.or-show-hidden {
    width: 5%;
  }
}

.or-luminaire-driver-tile-burn-hours {
  width: 14%;

  &.or-show-hidden {
    width: 5%;
  }
}

.or-luminaire-driver-tile-installed {
  width: 14%;

  &.or-show-hidden {
    width: 5%;
  }
}

.or-luminaire-driver-tile-energy-consumption {
  width: 20%;

  &.or-show-hidden {
    width: 8%;
  }
}

.or-luminaire-driver-tile-tags {
  width: 15%;
  padding-left: 10px;

  &.or-show-hidden {
    width: 12%;
  }
}

.or-luminaire-driver-tile-tag {
  display: inline-block;
  padding-right: 2px;
  vertical-align: top;

  &:last-child {
    padding-right: 0;
  }
}

.or-luminaire-driver-tile-tag-thumbnail {
  border-radius: 2px;
  display: inline-block;
  height: 12px;
  margin: 1px;
  vertical-align: top;
  width: 12px;
}

.or-luminaire-driver-tile-details {
  padding: 10px 0 0;
}

.or-luminaire-driver-tile-details-section {
  border-top: $border-outline;
  padding: 10px 0;

  &:last-child {
    padding-bottom: 0;
  }
}

.or-luminaire-driver-tile-details-tags {
  display: block;
}

.or-luminaire-driver-tile-details-tags-item {
  display: inline-block;
  padding-right: 4px;
  vertical-align: top;

  &:last-child {
    padding-right: 0;
  }
}

.or-luminaire-driver-tile-details-tag {
  border-radius: 3px;
  color: $text-inverse;
  display: inline-block;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 1.6em;
  padding: 0 8px;
}

.or-luminaire-driver-tile-details-errors {
  display: block;
}

.or-luminaire-driver-tile-details-errors-item {
  background-color: rgba($bg-negative, 0.1);
  border-radius: 3px;
  color: $text-negative;
  display: block;
  margin-bottom: 2px;
  padding: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.or-show {
  opacity: 1;
}

.or-selected {
  box-shadow: 0 0 4px 3px #09d;
}
