@import 'variables.scss';

#orHeader {
  background: $bg-inverse;
  color: $text-inverse;
  padding: 8px 13px;
  position: relative;
  z-index: 1000;

  &:after {
    clear: both;
    content: '';
    display: block;
  }

  .or-portal-logo {
    float: left;
  }

  .or-site-menu {
    float: left;
    padding-left: 20px;
  }

  .or-user-menu {
    float: right;
    padding-left: 20px;
  }

  .or-dropdown {
    &:not(.or-active) {
      .or-dropdown-trigger {
        background-color: transparent;
        color: $text-inverse;
      }
    }
  }

  .or-dropdown-trigger {
    opacity: 1;
    border-radius: $icon-size-medium;
  }
}
