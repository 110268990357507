@import 'variables.scss';

.or-floorplan {
  background: $bg-subtle;
  box-shadow: inset 0 0 0 1px $outline;
  overflow: hidden;
  position: relative;
  height: 65vh;
  width: 100%;

  .or-floorplan-buttons {
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }

  .loading-tint {
    background: $bg-muted;
    opacity: 0.6;
  }
}

.or-floorplan-notification-no-data {
  left: 50%;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  $-webkit: transform translate(-50%, -50%);
  width: auto;
  z-index: 20;
  pointer-events: none;
}

.or-floorplan-container {
  cursor: grab;
  cursor: -webkit-grab;
  width: 100%;
  height: 100%;

  &.is-dragging {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }

  &.is-active {
    .or-floorplan-buttons {
      opacity: 1;
    }
  }

  * {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

.or-floorplan-tint {
  background: $bg-muted;
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.or-floorplan-select-area-container {
  position: absolute;
  z-index: 3;
}

.or-floorplan-select-area {
  background: rgba($bg-accent, 0.4);
  border: dashed 1px $bg;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px $bg-accent, 0 0 0 1px $bg-accent;
  left: 30px;
  position: absolute;
  top: 50px;
}

.or-floorplan-image {
  border-radius: 3px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity $transition-slow;
  z-index: 1;

  &.or-show-nodes {
    opacity: 0.6;
  }
}

.reset-max-width {
  max-width: unset;
}

.or-floorplan-image-bg {
  width: 100%;
  height: 100%;
  background: $bg-dark-grey;
  position: absolute;
  transition: transform $transition-medium;
}

.or-floorplan-nodes {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 6;
  transition: opacity $transition-slow;

  &.or-floorplan-nodes-zoom-transition {
    transition: transform $transition-medium, opacity $transition-slow;
  }
}

.or-floorplan-heatmap {
  &.or-floorplan-heatmap-zoom-transition {
    transition: transform $transition-medium;
  }
}

.or-floorplan-nodes.ng-hide {
  opacity: 0;
}

.or-floorplan-nodes-muted {
  opacity: 0.4;
}

@media (min-height: 600px) {
  .or-floorplan {
    height: 70vh;
  }
}

@media (min-height: 660px) {
  .or-floorplan {
    height: 73vh;
  }
}

@media (min-height: 720px) {
  .or-floorplan {
    height: 78vh;
  }
}

@media (min-height: 920px) {
  .or-floorplan {
    height: 80.5vh;
  }
}

@media (min-height: 960px) {
  .or-floorplan {
    height: 82.5vh;
  }
}

@media (min-height: 1200px) {
  .or-floorplan {
    height: 83vh;
  }
}
