@import 'variables.scss';
@import 'mixins.scss';

.or-form-mobile-token-details {
  padding: 0;
  display: block;

  &.or-form-mobile-token-details-enable-labels {
    .or-label {
      display: block;
    }
  }

  .or-label {
    display: none;
    font-size: 0.9rem;
    padding: 0;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .or-form-item {
    vertical-align: bottom;
    width: auto;

    &.or-form-mobile-token-details-token {
      width: 35%;
    }

    &.or-form-mobile-token-details-description {
      width: 45%;
    }
  }
}
