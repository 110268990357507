@import 'variables.scss';
@import 'mixins.scss';

.or-image-upload {
  background-color: $bg;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  &:hover {
    .or-image-upload-preview {
      transform: scale(1.2);
    }

    .or-image-upload-button {
      opacity: 0.6;
    }
  }

  &.ng-empty {
    .or-image-upload-button {
      background-color: $bg;
      color: $text;
      opacity: 0.4;

      .or-icon-camera {
        background-position: top;
      }
    }
  }
}

.or-image-upload-preview {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1);
  transition: transform $transition-medium, -webkit-transform $transition-medium;
}

.or-image-upload-button {
  background-color: $bg-inverse;
  border-radius: 6px;
  bottom: 0;
  color: $text-inverse;
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity $transition-medium;
}

.or-image-upload-button-label {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;

  .or-button-label {
    display: block;
    text-align: center;
    line-height: 1;
  }
}

.or-image-upload-input {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
