.or-users-invited-heading {
  font-weight: bold;
  padding: 20px 0 0;
}

.or-users-invited-email {
  width: 20%;
  min-width: 250px;
}

.or-users-invited-token {
  width: 40%;
  min-width: 600px;
}

.or-users-invited-link {
  width: 10%;
  min-width: 200px;
}
