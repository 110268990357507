@import 'variables.scss';

.or-floorplan-actions {
  padding: 10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 8;

  li {
    margin: 4px;
    width: 0;
  }

  button {
    background-color: $bg;
    border-radius: 100px;
    line-height: 1;
    padding: 4px;
    opacity: 1;

    &:focus {
      box-shadow: $control-outline;
    }

    &:hover {
      background-color: $bg-hover;
    }

    &.or-active {
      background-color: $bg-inverse;

      .or-icon {
        background-position: center center;
      }
    }
  }
}
