.administration-mobile-tokens {
  .or-form-list {
    display: inline-block;
    vertical-align: top;
  }
}

.or-box {
  width: 49%;
  float: left;
  margin-left: 10px;
  padding-top: 10px;
  padding-left: 10px;
  background-color: #f3f0ed;
  border-radius: 5px 5px;
}
