@import 'variables.scss';
@import 'mixins.scss';

.or-beacon-settings {

  width: 300px;

  .or-form-item {
    width: 30%;
    display: block;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bg-negative;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $bg-positive;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .or-beacon-settings-list {
    display: inline-block;
    vertical-align: top;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 2px 2px;
    height: 600px;

    .or-beacon-settings-item {
      margin-bottom: 10px;
      padding-left: 10px;
      padding-top: 10px;
      padding-right: 10px;
      border-radius: 5px 5px;
      width: 100%;
      height: 25%;
      box-shadow: $shadow-light;
    }
  }

  .or-power-dbm {
    color: white;
    opacity: 1;
    width: 65px;
    margin-bottom: 5px;
    font-size: 0.9em;
    alignment: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #3A7EB1;
    border-style: none;
  }

  .or-icon-shadow {
    -webkit-filter: drop-shadow(0px 0px 2.5px $bg-dark-grey);
  }

  .or-beacon-list-item {
    border-radius: 3px;
    margin-bottom: 3px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.or-active {
      .or-tags-list-toggle {
        font-weight: 600;
        opacity: 1;
      }

      .or-tags-list-item-checkmark {
        visibility: visible;
      }
    }
  }

  .or-beacon-item-margin {
    margin-top: 10px;
  }

  .or-form-footer {
    .or-inline-item {
      .or-button {
        width: 115px;
      }
    }
  }
}

datalist {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

datalist option {
  font-size: 0.9em;
}
