@import 'variables.scss';

#orIntegrationsAreas {
  .or-integrations-areas-filters {
    width: 100%;
    margin-bottom: 16px;

    [type='search'] {
      width: 240px;
    }
  }

  .or-bacnet-area-list-item {
    margin: 16px 0;
  }
}
