@import 'variables.scss';
@import 'mixins.scss';

.or-dropdown {
  display: inline-block;
  position: relative;
  vertical-align: top;
  z-index: 100;

  .or-dropdown-content {
    display: none;
  }

  &.or-active {
    .or-dropdown-trigger {
      box-shadow: $control-outline-focus;

      &:after {
        background-position: top;
        transform: translateY(-50%) rotate(-180deg);
        -webkit-transform: translateY(-50%) rotate(-180deg);
      }
    }

    .or-dropdown-content {
      display: block;
    }
  }
}

.or-dropdown-trigger {
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 32px;
  position: relative;

  &:after {
    background-color: transparent;
    background-image: url('../../../../#{$path-icons}/16.unfold.svg');
    background-position: top;
    background-repeat: no-repeat;
    content: '';
    height: $icon-size-tiny;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) rotate(0);
    -webkit-transform: translateY(-50%) rotate(0);
    transition: transform $transition-fast, -webkit-transform $transition-fast;
    width: $icon-size-tiny;
  }
}

.or-dropdown-trigger-inverse {
  &:after {
    background-position: center;
  }
}

.or-dropdown-content {
  background-color: $bg;
  border-radius: 3px;
  box-shadow: 0 10px 10px 0 rgba($bg-inverse, 0.1), 0 10px 30px 0 rgba($bg-inverse, 0.1);
  left: -10px;
  margin: 10px;
  min-width: 100%;
  padding: 10px;
  position: absolute;
  top: 100%;
}
