@import 'variables.scss';
@import 'mixins.scss';

.or-inline-floors {
  display: inline-block;
  font-size: inherit;
  vertical-align: middle;

  &.or-editable {
    font-size: 1rem;

    .or-inline-floor {
      line-height: $icon-size-medium;
      padding-right: $icon-size-small;
    }

    .or-inline-floor-number {
      border-radius: $icon-size-small;
      font-size: 0.8rem;
      height: $icon-size-small;
      line-height: $icon-size-small;
      min-width: $icon-size-small;
    }

    .or-inline-floor-name {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.or-inline-floor {
  position: relative;
}

.or-inline-floor-number {
  background-color: $bg-inverse;
  border-radius: $icon-size-tiny;
  color: $text-inverse;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  height: $icon-size-tiny;
  line-height: $icon-size-tiny;
  margin-right: 4px;
  min-width: $icon-size-tiny;
  padding: 0 4px;
  text-align: center;
  vertical-align: middle;
}

.or-inline-floor-name {
  color: $text;
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
}

.or-inline-floor-remove {
  border-radius: 0;
  bottom: 0;
  box-shadow: none !important;
  min-width: auto !important;
  opacity: 0.4;
  padding: 0 !important;
  position: absolute !important;
  right: 0;
  top: 0;
  transition: opacity $transition-fast;
  width: $icon-size-small !important;

  &:hover {
    opacity: 1;
  }

  .or-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
