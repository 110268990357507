@import 'variables.scss';

.or-floorplan-actions,
.or-tray {
  transition: linear all $transition-medium-ng;

  .or-button-tray {
    margin-left: 10px;
  }

  button {
    background-color: $bg;
  }
}

.or-tray.ng-hide {
  opacity: 0;
}
