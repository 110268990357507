@import './variables.scss';

.or-form {
  padding: 5px 0;
  display: block;

  &.or-form-inline {
    display: inline-block;
  }
}

.or-form-header {
  padding-bottom: 10px;
  padding: 10px;
}

.or-form-title {
  font-size: 1.4rem;
  letter-spacing: -0.05em;
}

.or-form-body {
  padding: 0;
}

.or-form-items {
  display: block;
}

.or-form-item:not(mat-form-field) {
  padding: 5px;
}

.or-form-item  {
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  &.or-form-item-inline {
    width: auto;

    .or-button,
    .or-input,
    textarea,
    .or-select {
      width: auto;
    }
  }
}

.or-form-item-status {
  vertical-align: middle;
}

.or-form-footer {
  padding-top: 10px;
}

.or-form-actions {
  padding: 5px;
}

.or-form-action {
  display: inline-block;
  padding: 5px;
  vertical-align: top;
}

label,
.or-label {
  display: inline-block;
  line-height: $icon-size-medium;
  opacity: 0.8;
  padding-right: 4px;
  vertical-align: middle;

  &.or-label-above {
    display: block;
  }

  &.or-label-right {
    margin-left: 4px;
  }

  &.or-label-left {
    margin-right: 4px;
  }

  &.or-label-warning {
    color: red;
  }
}

.or-bold {
  font-weight: bold;
}

.or-button,
.or-input,
.or-select,
textarea {
  background-color: $bg;
  border: 0;
  border-radius: 6px;
  box-shadow: $control-outline;
  color: $text;
  display: inline-block;
  font: inherit;
  line-height: $icon-size-medium;
  margin: 0;
  opacity: 0.8;
  outline: 0;
  width: 100%;
  padding: 0 8px;
  transition: opacity $transition-fast, box-shadow $transition-fast;
  vertical-align: top;

  .or-button-tooltip {
    background: $bg-inverse;
    color: $text-inverse;
    line-height: 25px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity $transition-medium, width $transition-medium;
    white-space: nowrap;
    z-index: -10;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;

    &.or-button-tooltip-right {
      border-radius: 0 6px 6px 0;
      left: 40px;
    }

    &.or-button-tooltip-top-right {
      border-radius: 6px;
      bottom: 36px;
      left: 6px;
    }

    &.or-button-tooltip-bottom-right {
      border-radius: 6px;
      bottom: -28px;
      left: 6px;
    }

    &.or-button-tooltip-left {
      border-radius: 6px 0 0 6px;
      padding-left: 0;
      padding-right: 10px;
      right: 40px;
      text-align: right;
    }
  }

  &.or-active {
    .or-button-tooltip {
      background: $bg-accent;
      color: $text-inverse;
    }

    .hide-on-click {
      display: none;
    }
  }

  &:hover {
    .or-button-tooltip {
      opacity: 1;
      width: auto;

      &.or-button-longer-tooltip {
        width: 225px;
      }
    }
  }

  &:focus {
    box-shadow: $control-outline-focus;
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[disabled],
.or-disabled {
  opacity: 0.4 !important;
  * {
    cursor: not-allowed !important
  }
}

.or-select {
  height: $icon-size-medium;
}

.or-button {
  border-radius: $icon-size-medium;
  min-height: $icon-size-medium;
  min-width: $icon-size-medium;

  &.or-small {
    min-height: $icon-size-small;
    min-width: $icon-size-small;
    width: $icon-size-small;
    height: $icon-size-small;
  }

  &.or-iconic {
    padding: 0 4px;
    position: relative;

    .or-icon {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.or-primary {
    background-color: $bg-accent !important;
    color: $text-inverse !important;
  }

  &.or-positive {
    background-color: $bg-positive !important;
    color: $text-inverse !important;
  }

  &.or-mild-warning {
    background-color: $bg-mild-warning !important;
    color: $text-inverse !important;
  }

  &.or-warning {
    background-color: $bg-warning !important;
    color: $text-inverse !important;
  }

  &.or-negative {
    background-color: $bg-negative !important;
    color: $text-inverse !important;
  }

  &.or-unknown {
    background-color: $bg-light-grey !important;
    color: $text-inverse !important;
  }

  &.or-deactivate {
    background-color: $bg-deactivate !important;
    color: $text-inverse !important;
  }
}

.or-button-tray {
  position: relative;
  width: 24px;
  height: 24px;
}

.or-select:not(:disabled),
.or-button {
  cursor: pointer;
  padding: 0 16px;
}

.or-button-label {
  display: inline-block;
  line-height: $icon-size-medium;
  vertical-align: middle;
}

.or-rounded {
  border-radius: 100px;
}

.or-no-shadow {
  box-shadow: none;
}
