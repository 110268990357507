@import 'variables.scss';

.or-circadian-curves-timescale-container {
  padding-left: 24px;
  position: relative;
}

.or-circadian-curves-timescale {
  display: block;
  margin: 0 0;
  min-height: 20px;
  position: relative;
  width: 100%;
}

.or-circadian-curves-timescale-ticks {
  border-right: $border-outline;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
}

.or-circadian-curves-timescale-tick {
  border-left: $border-outline;
  display: inline-block;
  height: 100%;
  position: relative;
  vertical-align: top;
  width: 4.166666666%;
}

.or-circadian-curves-timescale-tick-label {
  color: $text-muted;
  font-size: 0.8rem;
  left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  margin: 10px 0 10px -2.5ch;

  &.or-circadian-curves-timescale-label-above {
    bottom: 100%;
  }

  &.or-circadian-curves-timescale-label-below {
    top: 100%;
  }
}

.or-circadian-curves-list {
  display: block;
  margin: 0;
}

.or-circadian-curves-list-item {
  display: block;
  position: relative;
}
