$path-assets: '../assets';
$path-icons: $path-assets + '/icons';
$path-images: $path-assets + '/images';

$icon-size-micro: 8px;
$icon-size-tiny: 16px;
$icon-size-small: 24px;
$icon-size-medium: 32px;
$icon-size-large: 48px;
$icon-size-xlarge: 56px;
$icon-size-2xlarge: 88px;

$width-hours-controls: 100px;
$width-hours: 70px;
$width-half-hours: 35px;

$bg-page: #f9f6f3;

$bg: #fff;
$bg-muted: rgba($bg, 0.4);
$bg-partial-muted: rgba($bg, 0.8);
$bg-semi-transparent: rgba(#dddddd, 0.6);
$bg-light-grey: #d9d9d9;
$bg-dark-grey: #777;
$bg-deep-grey: #666;
$bg-subtle: rgba(#f9f6f3, 0.8);
$bg-inverse: #111;
$bg-inverse-muted: rgba($bg-inverse, 0.8);
$bg-inverse-disabled: rgba($bg-inverse, 0.4);
$bg-accent: #09d;
$bg-accent-muted: rgba($bg-accent, 0.8);
$bg-positive: #8c4;
$bg-mild-warning: #ffe400;
$bg-warning: #f80;
$bg-negative: #d22;
$bg-negative-light: #ecc;
$bg-negative-dark: #f88;
$bg-deactivate: #c00;
$bg-hover: #c90;
$bg-table-header: #b9b9b98c;

$text: $bg-inverse;
$text-muted: rgba($text, 0.4);
$text-inverse: $bg;
$text-dark: #555555;
$text-inverse-light: #ccc;
$text-inverse-muted: rgba($text-inverse, 0.6);
$text-negative: #800;
$text-positive: #080;
$text-warning: #f80;
$text-accent: $bg-accent;

$transition-fast: 0.1s ease;
$transition-medium: 0.3s ease;
$transition-slow: 0.7s ease;
$transition-fast-ng: 0.1s;
$transition-medium-ng: 0.3s;

$outline: rgba($bg-inverse, 0.2);
$outline-inverse: rgba($bg, 0.2);

$border-outline: solid 1px rgba($outline, 0.1);
$border-outline-inverse: solid 1px rgba($outline-inverse, 0.1);

$shadow: 0 1px 1px 0 rgba($bg-inverse, 0.1), 0 1px 3px 0 rgba($bg-inverse, 0.1);
$shadow-light: 0 0 15px 0px #999;
$shadow-dark: 0 0 10px 1px #444;

$control-outline: inset 0 0 0 1px rgba($bg-inverse, 0.1), inset 0 -1px 0 0 rgba($bg-inverse, 0.1);
$control-outline-focus: inset 0 0 0 1px rgba($bg-inverse, 0.1), inset 0 -1px 0 0 rgba($bg-inverse, 0.1), inset 0 0 0 3px rgba($bg-inverse, 0.1);

$dialog-info-bg: #ffffff;
$dialog-info: #3183C8;
$dialog-info-mild: #AAD4F5;
$dialog-info-text-title: #12283A;
$dialog-info-text-content: #1B4971;

$dialog-warning-bg: #FFFCF4;
$dialog-warning: #F4CA64;
$dialog-warning-mild: #FAE29F;
$dialog-warning-text-title: #5C4813;
$dialog-warning-text-content: #8C6D1F;

$dialog-error-bg: #FAFAFA;
$dialog-error: #ed6868;
$dialog-error-mild: #F4ABAA;
$dialog-error-text-title: #5F1717;
$dialog-error-text-content: #881A1B;

$form-error-bg: #ffe8e8;

$menu-border-bottom: #e7e7e7;
