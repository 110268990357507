.or-metric-widget-chart {
  padding: 10px 0px;
  position: relative;
}
.or-metric-widget-chart .or-metric-widget-chart-content {
  overflow: visible;
  position: relative;
  width: 100%;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar {
  cursor: pointer;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar.or-metric-widget-chart-bar-selected .or-metric-widget-chart-bar-fill,
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar.or-metric-widget-chart-bar-selected .or-metric-widget-chart-bar-label {
  opacity: 1;
  font-weight: bold;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar:hover .or-metric-widget-chart-bar-fill,
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar:hover .or-metric-widget-chart-bar-label {
  opacity: 1;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar-container {
  fill: transparent;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar-fill {
  fill: #fff;
  opacity: 0.4;
  shape-rendering: crispEdges;
  transition: opacity 0.1s ease;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar-tip {
  fill: #fff;
  opacity: 1;
  shape-rendering: crispEdges;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar-label {
  fill: #fff;
  font-size: 0.7rem;
  opacity: 0.8;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar-value {
  text-anchor: start;
}
.or-metric-widget-chart .or-metric-widget-chart-content .or-metric-widget-chart-bar-timestamp {
  text-anchor: end;
}
.or-metric-widget-chart.or-inverse .or-metric-widget-chart-bar-fill {
  fill: #111;
}
.or-metric-widget-chart.or-inverse .or-metric-widget-chart-bar-tip {
  fill: #111;
}
.or-metric-widget-chart.or-inverse .or-metric-widget-chart-bar-label {
  fill: #111;
}