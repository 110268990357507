@import 'variables.scss';

.or-search-input {
  position: relative;

  .or-icon-search {
    cursor: pointer;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);

    &:active {
      opacity: 1;
    }
  }

  input {
    padding-left: 28px;
  }

  input + .or-icon-search {
    opacity: 0.2;
  }

  input:focus + .or-icon-search {
    opacity: 0.6;
  }

  input:hover + .or-icon-search {
    opacity: 0.4;
  }
}
