@import 'variables.scss';

.or-tabs {
  display: block;
  margin: 20px 0;
}

.or-tab {
  color: $text-muted;
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: -0.05em;
  padding-right: 20px;
  vertical-align: bottom;

  &:last-child {
    padding-right: 0;
  }

  &.or-active {
    font-size: 1.6rem;
    color: $text;
  }
}

.or-tab-link {
  color: inherit;
  display: block;
  text-decoration: none;
}
