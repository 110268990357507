@import 'variables.scss';

.or-site-menu-link {
  background-color: transparent;
  color: $text-inverse;
  opacity: 1;
  padding: 0;

  .or-button-label {
    display: none;
  }

  &.or-active {
    background-color: $bg-accent;
    color: $text-inverse;
    padding: 0 16px;

    .or-button-label {
      display: inline-block;
    }
  }
}
