@import 'variables.scss';
@import 'mixins.scss';

.or-panel-toggle-heading {
  width: 100%;
}

.or-panel-toggle {
  font-size: 1.2rem;
  letter-spacing: -0.05em;

  &:not(.or-active) {
    .or-panel-toggle-icon {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $bg-inverse;
    }

    .or-panel-toggle-label {
      display: none;
    }
  }
}

.or-panel-toggle-button {
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
  padding: 0;
  text-align: left;

  &:focus {
    box-shadow: none;
  }
}

.or-panel-toggle-icon {
  background-color: $bg-inverse;
  border-radius: $icon-size-medium;
  box-shadow: none;
  display: inline-block;
  height: $icon-size-medium;
  position: relative;
  width: $icon-size-medium;
  vertical-align: middle;

  .or-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.or-panel-toggle-label {
  padding-left: 8px !important;
}

.or-panel-print-button {
  width: 32px;
  text-align: left;
  padding-left: 4px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.or-timezone-selector {
  width: 350px;
}
