.or-form-building-details {
  .or-form-items {
    display: inline-block;
    vertical-align: top;
  }
}

.or-form-building-details-summary {
  width: 50%;
}

.or-form-building-details-postcode {
  width: 20%;
}

.or-form-building-details-country {
  width: 40%;
}

.or-form-building-details-timezone {
  width: 40%;
}

.or-form-building-details-photo {
  width: 50%;

  .or-form-building-details-photo-upload {
    width: 360px;
    height: 360px;
  }
}
