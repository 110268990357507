@import 'variables.scss';

.or-pub-sub-connections {
}

.pub-sub-line {
  fill: none;
  stroke: $bg-accent;
  stroke-width: 1px;
  stroke-dasharray: 5, 3;
}

.pub-sub-lines-svg {
  width: 100vw;
  height: 100vh;
}
