@import 'variables.scss';

$bg-page-solid: rgba($bg-page, 1);
$bg-page-transparent: rgba($bg-page, 0);

#orBeacons {
  .or-panel-selector {
    margin: 5px 5px 0;
  }

  .or-content {
    height: 88vh;
  }

  .or-panel {
    margin: 5px;
  }

  .or-panel-nodes {
    &.or-active {
      flex-basis: 400px;
      flex-grow: 1;
    }

    .or-panel-content {
      position: relative;
      height: 100%;

      &:before,
      &:after {
        background: $bg-page;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
      }
    }
  }

  .or-panel-container {
    &.or-active {
      flex-basis: 1200px;
    }
  }

  .or-panel-floorplan {
    &.or-active {
      flex-basis: 500px;
      flex-grow: 1;
    }
  }

  .or-panel-tags {
    &.or-active {
      width: 300px;
      margin-left: 200px;
    }
  }

  .or-panel-settings {
    &.or-active {
      flex-basis: 300px;
    }
  }

  .align-right-top {
    width: 100%;
  }

  .or-button-li {
    float: right;
  }

  .beacon-button-ul {
    height: 34px;
  }

  .or-inline-items {
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  .or-inline-item {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
