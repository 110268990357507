@import 'variables.scss';

.or-lighting-test-report-labels {
  .or-elt-report-nodes {
    display: flex;
    flex-wrap: nowrap;
  }
}

.or-lighting-test-report,
.or-lighting-test-report-labels {
  border-bottom: solid 1px rgba($text, 0.1);
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  padding-bottom: 8px;
  padding-top: 16px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  & > * {
    padding: 8px;
  }

  .or-elt-report-label {
    display: inline-block;
    font-size: 0.9rem;
    opacity: 0.6;
    vertical-align: top;
  }

  .or-elt-report-icon-wrap {
    background-color: $text;
    border-radius: 32px;
    display: block;
    height: 32px;
    position: relative;
    width: 32px;

    .or-icon {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .or-elt-report-icon {
    padding-top: 0;
    width: 4%;
  }

  .or-elt-report-group {
    width: 15%;
  }

  .or-elt-report-date {
    width: 13%;
  }

  .or-elt-report-type {
    width: 6%;
  }

  .or-elt-report-nodes {
    width: 55%;
  }

  li.or-elt-report-node {
    display: flex;
    flex-wrap: nowrap;
  }

  .or-elt-report-node {
    &.or-elt-report-node-failed {
      color: $text-negative;
    }

    &.or-elt-report-node-active {
      color: $text-positive;
    }

    &.or-elt-report-node-cancelled {
      color: rosybrown;
    }

    &.or-elt-report-node-timed-out {
      color: coral;
    }

    &.or-elt-report-node-scheduled {
      color: dodgerblue;
    }
  }

  .or-elt-report-clickable {
    display: inline-block;
    width: 60%;
  }

  .or-elt-report-clickable-highlighted {
    display: inline;
    cursor: pointer;

    &:hover {
      span {
        font-weight: bold;
        text-shadow: 0 0 4px $bg-light-grey;
      }
    }
  }

  .or-elt-report-node-id {
    display: inline-block;
    padding-bottom: 8px;
    padding-right: 4px;
    vertical-align: top;
    width: 16%;
  }

  .or-elt-report-driver-id {
    display: inline-block;
    padding-bottom: 8px;
    padding-right: 4px;
    vertical-align: top;
    width: 16%;
  }

  .or-elt-report-node-supply-status {
    display: inline-block;
    padding-bottom: 8px;
    vertical-align: top;
    width: 15%;
  }

  .or-elt-report-node-status {
    display: inline-block;
    padding-bottom: 8px;
    padding-right: 4px;
    vertical-align: top;
    width: 19%;
  }

  .or-elt-report-node-message {
    display: inline-block;
    padding-bottom: 8px;
    padding-right: 4px;
    vertical-align: top;
    width: 48%;
  }

  .or-elt-report-node-notes {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    resize: vertical;
  }
}

.or-lighting-test-report-labels {
  padding-bottom: 8px !important;
  padding-top: 8px !important;

  .or-elt-report-status {
    font-weight: inherit;
  }
}

.or-lighting-test-report.or-elt-report-failed {
  .or-elt-report-icon-wrap {
    background-color: $bg-negative;
  }

  .or-elt-report-status {
    color: $text-negative;
  }
}

.or-lighting-test-report.or-elt-report-succeeded {
  .or-elt-report-icon-wrap {
    background-color: $bg-positive;
  }

  .or-elt-report-status {
    color: $text-positive;
  }
}

.or-lighting-test-report.or-elt-report-running {
  .or-elt-report-icon-wrap {
    background-color: $bg-accent;
  }

  .or-elt-report-status {
    color: $text-accent;
  }
}

.or-lighting-test-report.or-elt-report-timedOut {
  .or-elt-report-icon-wrap {
    background-color: $bg-warning;
  }

  .or-elt-report-status {
    color: $text-warning;
  }
}
