@import 'variables.scss';

$timerange-scale-tick-height: 8px;
$timerange-scale-tick-border: solid 1px rgba($outline, 0.15);
.or-timerange-widget {
  height: $timerange-scale-tick-height;
  margin: 16px 8px 32px;
  position: relative;

  .or-timerange-widget-scale {
    background-color: rgba($outline, 0.05);
    border-right: $timerange-scale-tick-border;
    height: $timerange-scale-tick-height;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  li {
    border-left: $timerange-scale-tick-border;
    display: inline-block;
    height: $timerange-scale-tick-height;
    position: relative;
    vertical-align: top;
    width: 4.166666666%;
  }

  .or-timerange-widget-label {
    font-size: 0.8rem;
    opacity: 0.6;
    position: absolute;
    top: 100%;
    transform: translateY(16px);
  }

  .or-timerange-widget-fill-container {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }

  .or-timerange-widget-fill {
    background-color: $bg-accent;
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 33%;
  }

  &.or-invalid-range .or-timerange-widget-fill {
    background-color: $bg-negative;
  }

  .or-timerange-widget-fill-label {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1rem;
    position: absolute;
    transform: translateX(-50%);
  }

  .or-timerange-widget-fill-label-start {
    bottom: 100%;
  }

  .or-timerange-widget-fill-label-end {
    top: 100%;
  }
}
