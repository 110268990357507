@import 'variables.scss';
@import 'mixins.scss';

.or-panel {
  &.or-solid {
    .or-panel-content {
      background-color: $bg;
      border-radius: 3px;
      box-shadow: $shadow;
      color: $text;
      padding: 10px;
    }
  }

  &:not(.or-active) {
    .or-panel-content {
      display: none;
      overflow: hidden;
    }
  }
}

.or-panel-content {
  margin-top: 10px;
}
