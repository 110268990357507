@import 'variables.scss';
@import 'mixins.scss';

.or-form-role-details {
  padding: 0;
  display: block;

  &.or-form-role-details-enable-labels {
    .or-label {
      display: block;
    }
  }

  .or-label {
    display: none;
    font-size: 0.9rem;
    padding: 0;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .or-form-item {
    vertical-align: bottom;
    width: auto;

    &.or-form-role-details-name {
      width: 20%;
    }

    &.or-form-role-details-authority {
      text-align: center;
      width: 4%;
    }
  }
}
