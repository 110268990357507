@import 'variables.scss';
@import 'mixins.scss';

.or-checkbox {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  height: $icon-size-small !important;
  line-height: $icon-size-small;
  margin: 4px 0;
  text-align: left;
  min-height: auto !important;
  min-width: auto !important;
  padding: 0 !important;
  vertical-align: middle;
  width: auto !important;

  &:focus {
    box-shadow: none;

    .or-checkbox-control {
      box-shadow: $control-outline-focus;
    }
  }

  &:not(.or-active) {
    .or-checkbox-checkmark {
      display: none;
    }
  }
}

.or-checkbox-control {
  border-radius: 6px;
  height: $icon-size-small !important;
  padding: 0;
  width: $icon-size-small !important;
  min-height: auto;
  min-width: auto;
  position: relative;

  & + .or-checkbox-label {
    padding-left: 4px;
  }
}

.or-checkbox-checkmark {
  height: $icon-size-tiny;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $icon-size-tiny;
  background-position: top center !important;
}

.or-checkbox-label {
  display: inline-block;
  line-height: $icon-size-small;
}
