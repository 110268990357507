@import 'variables.scss';
@import 'mixins.scss';

.or-failed-tests-counter {
  left: 50%;
  opacity: 0;
  position: absolute;
  top: -100%;
  transform: translateX(-50%);
  transition: top $transition-medium, opacity $transition-fast;
  z-index: 10;

  &.or-active {
    top: 10px;
    opacity: 1;
  }
}

.or-failed-tests-counter-body {
  background: $bg;
  border-radius: 3px;
  color: $text;
  padding: 8px;
  white-space: nowrap;

  &.or-failed-tests-counter-everything-passed {
    background: $bg-positive;
    color: $text-inverse;
  }

  &.or-failed-tests-counter-something-failed {
    color: $text-negative;
    animation: pulse $transition-slow infinite alternate;
  }
}

.or-failed-tests-counter-label {
  display: inline-block;
  padding: 2px;
}

.or-failed-tests-counter-total,
.or-failed-tests-counter-failed {
  font-weight: 600;
}

@-webkit-keyframes pulse {
  0% {
    background-color: $bg-negative-light;
  }
  100% {
    background-color: $bg-negative-dark;
  }
}

@-moz-keyframes pulse {
  0% {
    background-color: $bg-negative-light;
  }
  100% {
    background-color: $bg-negative-dark;
  }
}

@-ms-keyframes pulse {
  0% {
    background-color: $bg-negative-light;
  }
  100% {
    background-color: $bg-negative-dark;
  }
}

@-o-keyframes pulse {
  0% {
    background-color: $bg-negative-light;
  }
  100% {
    background-color: $bg-negative-dark;
  }
}

@keyframes pulse {
  0% {
    background-color: $bg-negative-light;
  }
  100% {
    background-color: $bg-negative-dark;
  }
}
