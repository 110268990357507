@import './variables.scss';

$metric-presence: #f44;
$metric-occupancy: #f80;
$metric-dim-level: #693;
$metric-light-level: #399;
$metric-energy-consumption: #369;
$metric-energy-occupancy-ratio: #639;
$metric-connection-quality: #639;

$metric-temperature: #880303;
$metric-pressure: #4454ff;
$metric-humidity: #d644ff;
$metric-fuji-ambient-light: #ccab2a;
$metric-sound: #ff44c4;
$metric-co2: #069a58;