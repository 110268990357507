.or-form-floor-details {
  width: 50%;

  .or-form-items {
    padding-left: 70px;
    padding-right: 84px;
    position: relative;
  }

  .or-form-item {
    width: auto;
  }

  .or-form-floor-details-number {
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 70px;

    input {
      text-align: inherit;
    }
  }

  .or-form-floor-details-name,
  .or-form-floor-details-note {
    width: 100%;
  }

  .or-form-floor-details-floorplan {
    position: absolute;
    right: 0;
    top: 0;
    width: 84px;
    height: 84px;
  }

  .or-form-floor-details-floorplan-upload {
    width: 100%;
    height: 100%;
  }
}
