@import 'variables.scss';
@import 'mixins.scss';

.or-lighting-configuration-node-tile-status {
  width: 12%;
}

.or-lighting-configuration-node-tile-id {
  color: $text-muted;
  width: 12%;

  .or-node-tile-value {
    font-size: 1.2rem;
    line-height: 28px;
  }
}

.or-lighting-configuration-group-id {
  color: $text-muted;
  width: 12%;

  .or-node-tile-value {
    font-size: 1.2rem;
    line-height: 28px;
  }
}

.or-lighting-configuration-node-tile-address {
  width: 30%;
}

.or-lighting-configuration-node-tile-zone {
  width: 8%;
}

.or-lighting-configuration-node-tile-update-status {
  width: 25%;
}

.or-green {
  color: #000;
}

.or-red {
  color: #777;
}

.or-orange {
  color: #c60;
}

.or-mouseover-zone, .or-mouseover-not-available {
  width: 85px;
  color: white;
  opacity: 0.9;
  text-align: center;
  background-color: #4397D8;
  text-align: center;
  border-radius: 3px;
  transition: 0.4s;
  z-index: 9100;
  position: relative;
}

.or-mouseover-zone {
  width: 120px;
}

.or-mouseover-not-available {
  background-color: #8EB9DA;
}

.or-opacity {
  opacity: 0.6;
}

.loader {
  width: 24px;
  height: 24px;
  display: inline-block;
  z-index: 9000;
  -webkit-animation: lighting-configuration-spin 0.25s linear infinite;
  animation: lighting-configuration-spin 0.25s linear infinite;
}

@-webkit-keyframes lighting-configuration-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-moz-keyframes lighting-configuration-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-ms-keyframes lighting-configuration-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-o-keyframes lighting-configuration-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@keyframes lighting-configuration-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
