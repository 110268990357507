@import 'variables.scss';
@import 'mixins.scss';

.or-buildings-menu {
  .or-dropdown-content {
    left: auto;
    padding-top: 0;
    right: -10px;
    width: 320px;
  }
}

.or-buildings-menu-list-item {
  border-bottom: $border-outline;
  padding: 10px 0;
  position: relative;
}

.or-buildings-menu-link {
  color: $text;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.or-buildings-menu-item-thumbnail {
  border-radius: $icon-size-large;
  height: $icon-size-large;
  left: 0;
  position: absolute;
  top: 10px;
  width: $icon-size-large;
}

.or-buildings-menu-item-summary {
  min-height: $icon-size-large;
  padding: 0 $icon-size-medium 0 $icon-size-xlarge;
}

.or-buildings-menu-item-title {
  display: block;
  font-size: 1.4rem;
  letter-spacing: -0.05em;
  overflow: hidden;
  padding-top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.or-buildings-menu-item-address {
  display: block;
  font-size: 0.9rem;
  opacity: 0.4;
  overflow: hidden;
  padding-top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.or-buildings-menu-settings {
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity $transition-fast;

  &:hover {
    opacity: 1;
  }
}

.or-buildings-menu-actions {
  padding-top: 10px;
}
