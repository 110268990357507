@import 'variables.scss';
@import 'mixins.scss';

.or-form-gateway-details {
  .or-form-item {
    width: auto;
  }

  .or-form-gateway-details-name {
    width: 300px;
  }

  .or-form-gateway-details-network-type {
    width: 200px;
  }

  .authorized.or-form-gateway-details-log {
    width: 150px;
  }

  .authorized.or-form-gateway-details-version {
    width: 100px;
  }

  .unauthorized.or-form-gateway-details-version {
    width: 0;
  }
}
