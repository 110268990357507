@import 'variables.scss';
@import 'mixins.scss';

.or-form-circadian-curve {
  display: block;
  position: relative;

  .or-form-item {
    width: auto;
  }

  .or-day-of-week {
    margin-right: 4px;
  }

  .or-day-of-week-toggle {
    min-width: 4em;
    padding: 0 8px;

    &.or-active {
      background-color: $bg-accent;
      color: $text-inverse;
    }
  }
}

.or-form-circadian-curve-details {
  display: block;
}

.or-form-circadian-curve-details-name {
  width: 20%;
}

.or-form-circadian-curve-details-from-time,
.or-form-circadian-curve-details-to-time {
  select {
    min-width: 10%;
  }
}

.or-form-circadian-curve-ruleset {
  padding: 10px 0;
}

.or-form-circadian-curve-ruleset-rule {
  border-top: $border-outline;
  padding: 10px 0;

  &:last-child {
    border-bottom: $border-outline;
  }
}

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}

.axisSteelBlue text {
  fill: steelblue;
}

.axisRed text {
  fill: red;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 60px;
  height: 18px;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}
